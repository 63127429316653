var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.isLoading() } },
        [
          _vm.ta !== null
            ? _c(
                "v-card-title",
                [
                  _c("span", { staticClass: "pr-8" }, [
                    _vm._v(
                      _vm._s(_vm.ta.accountName) +
                        " (" +
                        _vm._s(_vm.ta.nowwCustomerId) +
                        ")"
                    )
                  ]),
                  _c("span", { staticClass: "pr-2" }, [
                    _vm._v(_vm._s(_vm.ta.jobNumber))
                  ]),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        color: _vm.$_dashMixin_taStatusColor(
                                          _vm.ta.status
                                        ),
                                        left: ""
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$_dashMixin_getTAIcon(
                                            _vm.ta.status
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3407896065
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.ta.statusDisplay || "Not selected"))
                      ])
                    ]
                  ),
                  _c(
                    "v-chip",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: _vm.$_dashMixin_fileTypeColor(_vm.ta.fileType),
                        dark: _vm.$_dashMixin_fileTypeColorDark(_vm.ta.fileType)
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.ta.fileType) + "\n      "
                      )
                    ]
                  ),
                  !_vm.ta.nthRecordSubCount
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._g(
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            color: _vm.$_dashMixin_countColor(
                                              _vm.ta.count
                                            ),
                                            dark: ""
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm._f("commaNumber")(
                                                _vm.ta.count
                                              )
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1621061218
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$_dashMixin_countSendDaysLabel(_vm.ta.count)
                              )
                            )
                          ])
                        ]
                      )
                    : _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._g(
                                        {
                                          attrs: {
                                            color: _vm.$_dashMixin_countColor(
                                              _vm.ta.nthRecordSubCount
                                            ),
                                            dark: ""
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm._f("commaNumber")(
                                                _vm.ta.nthRecordSubCount
                                              )
                                            ) +
                                            "/" +
                                            _vm._s(
                                              _vm._f("commaNumber")(
                                                _vm.ta.count
                                              )
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            270103471
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("commaNumber")(_vm.ta.nthRecordSubCount)
                              ) +
                                " Nth record of " +
                                _vm._s(_vm._f("commaNumber")(_vm.ta.count)) +
                                " count. " +
                                _vm._s(
                                  _vm.$_dashMixin_countSendDaysLabel(
                                    _vm.ta.nthRecordSubCount
                                  )
                                )
                            )
                          ])
                        ]
                      ),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "info" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$_dashMixin_nowwCopyLink(
                                            _vm.ta
                                          )
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n            mdi-content-copy\n          "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3117540036
                      )
                    },
                    [_c("span", [_vm._v("Copy Target Audience")])]
                  ),
                  _vm.ta.fileType === "CSTM" && _vm.ta.campaignId === null
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            right: "",
                            "x-offset": "",
                            origin: "right",
                            transition: "slide-x-transition"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var onMenu = ref.on
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var onTooltip = ref.on
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          disabled:
                                                            _vm.ta
                                                              .campaignId !==
                                                            null,
                                                          color: "error"
                                                        }
                                                      },
                                                      Object.assign(
                                                        {},
                                                        onMenu,
                                                        onTooltip
                                                      )
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                mdi-close\n              "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Delete Target Audience")
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            102136237
                          )
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-text", { staticClass: "pa-5" }, [
                                _c("span", [
                                  _vm._v(
                                    "Are you sure you want to delete this Target Audience?"
                                  )
                                ])
                              ]),
                              _c("v-divider"),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "tertiary",
                                        disabled: _vm.isLoading()
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Cancel\n            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "error",
                                        loading: _vm.isLoading()
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$_dashMixin_deleteTA(
                                            _vm.ta
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Yes\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-expand-transition",
            [
              _vm.ta !== null
                ? _c(
                    "div",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "px-0 pt-0 pb-4",
                              attrs: { fluid: "", grid: "" }
                            },
                            [
                              _c("LabelValue", {
                                attrs: {
                                  label: "Contact Name",
                                  value: _vm.ta.contactName
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "PO Number",
                                  value: _vm.ta.poNumber
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "Resends",
                                  value: _vm.ta.numberOfResends
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "Order Date",
                                  value: _vm._f("displayDateFormat")(
                                    _vm.ta.orderDate
                                  )
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "Order Type",
                                  value: _vm.ta.orderType
                                }
                              })
                            ],
                            1
                          ),
                          _vm.ta.campaignId !== null
                            ? _c("CampaignDashboardItem", {
                                attrs: {
                                  "campaign-id": _vm.ta.campaignId,
                                  "hide-target-data": ""
                                }
                              })
                            : _vm._e(),
                          _c("v-divider", { staticClass: "my-4" }),
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.audienceTableHeaders,
                              items: _vm.audience,
                              "items-per-page": 15,
                              loading: _vm.audienceLoading
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "body.prepend",
                                  fn: function() {
                                    return [
                                      _vm.ta &&
                                      _vm.ta.status ===
                                        _vm.$_taStatus_Completed &&
                                      _vm.ta.personalizations.length > 0
                                        ? _c(
                                            "tr",
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: {
                                                    colspan:
                                                      _vm.staticTableHeaders
                                                        .length
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-medium"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Target Audience Personalization Coverage (%):"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.ta.personalizations,
                                                function(pers) {
                                                  return _c(
                                                    "td",
                                                    { key: pers.name },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color: _vm.getPersonalizationCoverageColor(
                                                              pers
                                                            ),
                                                            "text-color":
                                                              "white"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                pers.audienceCoverage
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              3315639670
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.isLoading()
                ? _c("v-skeleton-loader", {
                    attrs: { type: "article, article, table" }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }