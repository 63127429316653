<template>
  <div>
    <v-card :loading="isLoading()">
      <v-card-title v-if="ta !== null">
        <span class="pr-8">{{ ta.accountName }} ({{ ta.nowwCustomerId }})</span>
        <span class="pr-2">{{ ta.jobNumber }}</span>
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on }">
            <v-icon
              class="mr-2"
              :color="$_dashMixin_taStatusColor(ta.status)"
              left
              v-on="on"
            >
              {{ $_dashMixin_getTAIcon(ta.status) }}
            </v-icon>
          </template>
          <span>{{ ta.statusDisplay || 'Not selected' }}</span>
        </v-tooltip>
        <v-chip
          class="mr-2"
          :color="$_dashMixin_fileTypeColor(ta.fileType)"
          :dark="$_dashMixin_fileTypeColorDark(ta.fileType)"
        >
          {{ ta.fileType }}
        </v-chip>
        <v-tooltip
          v-if="!ta.nthRecordSubCount"
          top
        >
          <template v-slot:activator="{ on }">
            <v-chip
              class="mr-2"
              :color="$_dashMixin_countColor(ta.count)"
              dark
              v-on="on"
            >
              {{ ta.count | commaNumber }}
            </v-chip>
          </template>
          <span>{{ $_dashMixin_countSendDaysLabel(ta.count) }}</span>
        </v-tooltip>
        <v-tooltip
          v-else
          top
        >
          <template v-slot:activator="{ on }">
            <v-chip
              :color="$_dashMixin_countColor(ta.nthRecordSubCount)"
              dark
              v-on="on"
            >
              {{ ta.nthRecordSubCount | commaNumber }}/{{ ta.count | commaNumber }}
            </v-chip>
          </template>
          <span>{{ ta.nthRecordSubCount | commaNumber }} Nth record of {{ ta.count | commaNumber }} count. {{ $_dashMixin_countSendDaysLabel(ta.nthRecordSubCount) }}</span>
        </v-tooltip>
        <v-spacer />
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              class="mr-2"
              color="info"
              @click="$_dashMixin_nowwCopyLink(ta)"
              v-on="on"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>Copy Target Audience</span>
        </v-tooltip>
        <v-menu
          v-if="ta.fileType === 'CSTM' && ta.campaignId === null"
          right
          x-offset
          origin="right"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on: onMenu }">
            <v-tooltip top>
              <template v-slot:activator="{ on: onTooltip }">
                <v-icon
                  :disabled="ta.campaignId !== null"
                  class="mr-2"
                  color="error"
                  v-on="{ ...onMenu, ...onTooltip }"
                >
                  mdi-close
                </v-icon>
              </template>
              <span>Delete Target Audience</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-text class="pa-5">
              <span>Are you sure you want to delete this Target Audience?</span>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="tertiary"
                :disabled="isLoading()"
              >
                Cancel
              </v-btn>
              <v-btn
                color="error"
                :loading="isLoading()"
                @click="$_dashMixin_deleteTA(ta);"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-card-title>
      <v-expand-transition>
        <div v-if="ta !== null">
          <v-card-text>
            <v-container
              fluid
              grid
              class="px-0 pt-0 pb-4"
            >
              <LabelValue
                label="Contact Name"
                :value="ta.contactName"
              />
              <LabelValue
                label="PO Number"
                :value="ta.poNumber"
              />
              <LabelValue
                label="Resends"
                :value="ta.numberOfResends"
              />
              <LabelValue
                label="Order Date"
                :value="ta.orderDate | displayDateFormat"
              />
              <LabelValue
                label="Order Type"
                :value="ta.orderType"
              />
            </v-container>
            <CampaignDashboardItem
              v-if="ta.campaignId !== null"
              :campaign-id="ta.campaignId"
              hide-target-data
            />
            <v-divider class="my-4" />
            <v-data-table
              :headers="audienceTableHeaders"
              :items="audience"
              :items-per-page="15"
              :loading="audienceLoading"
            >
              <template v-slot:body.prepend>
                <!-- <tr v-if="getTargetId() !== null && getPersonalizations().length > 0">
                    <td
                      :colspan="staticTableHeaders.length"
                      class="text-right"
                    >
                      <span class="font-weight-medium">Default:</span>
                    </td>
                    <td
                      v-for="pers in getPersonalizations()"
                      :key="pers.name"
                    >
                      <v-text-field
                        v-model="defaultItem[personalizationToUnderscores(pers.name.toLowerCase())]"
                        dense
                        hide-details
                        :placeholder="pers.name"
                      />
                    </td>
                  </tr> -->
                <tr v-if="ta && ta.status === $_taStatus_Completed && ta.personalizations.length > 0">
                  <td
                    :colspan="staticTableHeaders.length"
                    class="text-right"
                  >
                    <span class="font-weight-medium">Target Audience Personalization Coverage (%):</span>
                  </td>
                  <td
                    v-for="pers in ta.personalizations"
                    :key="pers.name"
                  >
                    <v-chip
                      small
                      :color="getPersonalizationCoverageColor(pers)"
                      text-color="white"
                    >
                      {{ pers.audienceCoverage }}
                    </v-chip>
                  </td>
                </tr>
              </template>
              <!-- <template v-slot:item.action="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    color="success"
                    @click="editPersonalization(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-menu
                    right
                    x-offset
                    origin="right"
                    transition="slide-x-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        color="error"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>

                    <v-card>
                      <v-card-text class="pa-5">
                        <span>Are you sure you want to delete this target Contact?</span>
                      </v-card-text>

                      <v-divider />

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="tertiary"
                          :disabled="isLoading()"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="error"
                          :loading="isLoading()"
                          @click="removeContact(item)"
                        >
                          Yes
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </template> -->
            </v-data-table>
          </v-card-text>
        </div>
        <v-skeleton-loader
          v-else-if="isLoading()"
          type="article, article, table"
        />
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { enumsMixin } from '@/shared/mixins/general'
import { dashboardMixin } from '@/shared/mixins/mailing'
import { mapGetters } from 'vuex'
import { mailingService } from '@/shared/services'
// import { fileExtensions } from '@/shared/models/content-library'

export default {
  name: 'TargetAudience',

  components: {
    LabelValue: () => import('@/components/custom/LabelValue'),
    CampaignDashboardItem: () => import('@/views/pages/components/mailing/CampaignDashboardItem')
    // CampaignHeader: () => import('@/views/pages/components/mailing/CampaignHeader')
  },

  mixins: [enumsMixin, dashboardMixin],

  props: {
    targetAudienceId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      ta: null,
      audience: [],
      audienceLoading: false
    }
  },

  watch: {
    '$route': function () {
      this.getTAContents()
    },
    '$route.params.targetAudienceId': function (targetAudienceId) {
      this.getTAContents()
    }
  },

  created () {
    this.getTAContents()
  },

  computed: {
    ...mapGetters(['isLoading']),
    staticTableHeaders () {
      return [
        // { text: 'Actions', value: 'action', sortable: false, filterable: false },
        { text: 'Email', value: 'email' },
        { text: 'First Name (FNAME)', value: 'firstName' },
        { text: 'Last Name (LNAME)', value: 'lastName' },
        { text: 'Title (MDDO)', value: 'mddo' }
      ]
    },
    audienceTableHeaders () {
      if (this.ta) {
        return [
          ...this.staticTableHeaders,
          ...this.ta.personalizations.map(x => {
            return { text: this.$options.filters.titleCase(x.name), value: x.name.toLowerCase().replace(/ /g, '_') }
          })
        ]
      }
      return this.staticTableHeaders
    }
  },

  methods: {
    async getTAContents () {
      this.audienceLoading = true
      await mailingService.getTA(this.targetAudienceId)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            const { audience, ...taModel } = resp
            this.ta = taModel
            this.audience = audience
            // .map(item => {
            //   return {
            //     ...item,
            //     personalizations: item.personalizations.map(pers => {
            //       return {
            //         ...pers,
            //         text: this.$options.filters.titleCase(pers.name),
            //         name: pers.name.toLowerCase().replace(/ /g, '_')
            //       }
            //     })
            //   }
            // })
            this.audience.forEach((item, index) => {
              item.personalizations.forEach((pers, index2) => {
                item[pers.name.toLowerCase().replace(/ /g, '_')] = pers.value
              })
            })
          }
        })
        .finally(resp => {
          this.audienceLoading = false
        })
    },
    getPersonalizationCoverageColor (pers) {
      if (pers.audienceCoverage >= 100) return 'green'
      if (pers.audienceCoverage >= 75) return 'light-green'
      if (pers.audienceCoverage >= 50) return 'amber'
      if (pers.audienceCoverage >= 25) return 'orange'
      if (pers.audienceCoverage > 0) return 'deep-orange'
      if (pers.audienceCoverage <= 0) return 'red'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
